import { metaMask } from "@bkry/bowline-connectors";
import PropTypes from "prop-types";
import React from "react";
import { Badge, Card, CardBody, CardTitle } from "reactstrap";
import metamask from "../../assets/icons/metamask.svg";

const MetamaskWalletConnectButton = (props, context) => {
  //props from web 3 must be instantiated wherever function is used

  async function connectWallet() {
    try {
      if (window.ethereum && window.ethereum.isMetaMask) {
        metaMask
          .activate()
          .then(() => console.log("connected"))
          .catch((err) => console.log(err));
      }
    } catch (exception) {
      console.log("exception", exception);
      return exception;
    }
  }

  return (
    <>
      <Card
        style={{ cursor: "pointer" }}
        className="shadow-sm mr-5 mb-1 wallet-btn-wrapper"
        onClick={connectWallet}
      >
        <CardBody className="d-flex align-items-center">
          <img src={metamask} width={50} className="me-3" alt="metamask-icon" />
          <div className="">
            <CardTitle className="h5 font-weight-bold mb-1">
              MetaMask
              <Badge size="sm" color="info" className="ms-2">
                {context.t("Recommended")}
              </Badge>
            </CardTitle>
            <p>{context.t("Connect to your MetaMask Wallet")}</p>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

/**  define proptype for the 'translation' function  */
MetamaskWalletConnectButton.contextTypes = {
  t: PropTypes.func,
};

export default MetamaskWalletConnectButton;
