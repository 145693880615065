/**
 * The Dashboard container
 */
import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
} from "reactstrap";
import MetamaskWalletConnectButton from "../../components/ConnectWalletButton/MetamaskWalletConnectButton-component";
import { Link } from "react-router-dom";
import Logo from "../../assets/icons/bowline-logomark.svg";

/**
 * Dashboard container, the landing page for logged in users
 */
const Metamask = (props, context) => {
  return (
    <div className="Metamask">
      <Container>
        <div className="my-5">
          <Card className="shadow rounded border-0">
            <CardBody className="p-5">
              <div className="text-center mb-3">
                <img src={Logo} height={60} />
              </div>

              <CardTitle className="h3 text-center mb-5">
                {context.t("Creator App Sign In")}
              </CardTitle>
              <p>
                Bowline uses your wallet for account verification. Please
                connect and sign in with it. You are not asked to send any
                transaction or give access to your funds.
              </p>
              <MetamaskWalletConnectButton />
              <CardSubtitle className="mt-5 link-info">
                <Link to={{ pathname: "https://metamask.io/" }} target="_blank">
                  {context.t(
                    "Don't have a wallet yet? See here how to create it"
                  )}
                </Link>
              </CardSubtitle>
              <CardText className="mt-3 link-gray small">
                {context.t(
                  "With the click on connect your wallet you accept our terms and conditions"
                )}
              </CardText>
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
Metamask.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default Metamask;
